// paint
import mitchRock from './assets/art_images/paint/Mitchell Rock Utah.png';
import blue from './assets/art_images/paint/blue sphere horizon.png';
import girlInRoom from './assets/art_images/paint/girl in room.png';
import inside from './assets/art_images/paint/inside.png';
import la from './assets/art_images/paint/LA cezzanne.png';
import light from './assets/art_images/paint/light opens.png';
import power from './assets/art_images/paint/power.png';
import red from './assets/art_images/paint/red power.png';
import storm from './assets/art_images/paint/storm brewing.png';
import watercolor from './assets/art_images/paint/watercolor woman.png';

// sketch
import empty from './assets/art_images/sketch/empty woman.png';
import fertile from './assets/art_images/sketch/fertile ground.png';
import formSheres from './assets/art_images/sketch/form spheres.png';
import girlInnocent from './assets/art_images/sketch/girl innocent.png';
import icon from './assets/art_images/sketch/icon woman.png';
import naked from './assets/art_images/sketch/naked trees.png';
import road from './assets/art_images/sketch/road trip.png';
import spirit from './assets/art_images/sketch/spirit woman.png';
import still from './assets/art_images/sketch/still woman.png';
import waiting from './assets/art_images/sketch/waiting woman.png';
import we from './assets/art_images/sketch/we see.png';
import woman from './assets/art_images/sketch/woman weeps.png';
import womanTerror from "./assets/art_images/sketch/woman's terror.png";

// illustration
import did from './assets/art_images/illustration/did the sun explode.png';
import iWas from './assets/art_images/illustration/i was ordinary.png';
import inSummer from './assets/art_images/illustration/in summer cloud chased cloud.png';
import josephine from './assets/art_images/illustration/josephine.png';
import my from './assets/art_images/illustration/my young roots bundled in a ball I was transported.png';
import one from './assets/art_images/illustration/one tower fell.png';
import reborn from './assets/art_images/illustration/reborn in red-budded saplings the tree grows again.png';
import long from './assets/art_images/illustration/snipe book.png';
import snipeNite from './assets/art_images/illustration/snipe nite.png';
import treeWindow from './assets/art_images/illustration/the tree in the window.png';
import winter from './assets/art_images/illustration/the winter moon perched.png';
import treeLives from './assets/art_images/illustration/tree with three lives.png';
import upon from './assets/art_images/illustration/upon children waering colored stars.png';


export const paint = [
  {
    src: mitchRock,
    title: 'Mitchell Rock, Utah',
    type: 'oil on canvas',
    dimensions: '48 in x 36 in'
  },
  {
    src: blue,
    title: 'blue sphere horizon',
    type: 'oil on canvas',
    dimensions: '60 in x 48 in'
  },
  {
    src: light,
    title: 'light opens',
    type: 'oil on canvas',
    dimensions: '30 in x 30 in'
  },
  {
    src: power,
    title: 'power',
    type: 'oil on canvas',
    dimensions: '48 in x 36 in',
  },
  {
    src: red,
    title: 'red power',
    type: 'oil on canvas',
    dimensions: '12 in x 12 in',
  },
  {
    src: storm,
    title: 'storm brewing',
    type: 'oil on canvs',
    dimensions: '36 in x 48 in'
  },
  {
    src: la,
    title: 'LA cezzanne',
    type: 'oil on canvas',
    dimensions: '30 in x 40 in'
  },
  {
    src: girlInRoom,
    title: 'girl in room',
    type: 'watercolor',
    dimensions: '10 in x 8 in'
  },
  {
    src: watercolor,
    title: 'watercolor woman',
    type: 'watercolor',
    dimensions: '8 in x 11 in'
  },
  {
    src: inside,
    title: 'inside',
    type: 'watercolor',
    dimensions: '8 in x 11 in'
  }
];

export const sketch = [
    {
      src: empty,
      title: 'empty woman',
      type: 'ink on paper',
      dimensions: '11 in x 8.5 in'
    },
    {
      src: fertile,
      title: 'fertile ground',
      type: 'ink on paper',
      dimensions: '11 in x 8.5 in'
    },
    {
      src: girlInnocent,
      title: 'girl innocent',
      type: 'ink on paper',
      dimensions: '8.5 in x 11 in'
    },
    {
      src: icon,
      title: 'icon woman',
      type: 'ink on paper',
      dimensions: '8.5 in x 11 in'
    },
    {
      src: spirit,
      title: 'spirit woman',
      type: 'ink on paper',
      dimensions: '11 in x 8.5 in'
    },
    {
      src: still,
      title: 'still woman',
      type: 'ink on paper',
      dimensions: '8.5 in x 11 in'
    },
    {
      src: waiting,
      title: 'waiting woman',
     type: 'ink on paper',
      dimensions: '11 in x 8.5 in'
    },
  {
    src: woman,
    title: 'woman weeps',
    type: 'ink on paper',
    dimensions: '11 in x 8.5 in'
    },
    {
      src: we,
      title: 'we see',
      type: 'ink on paper',
      dimensions: '11 in x 8.5 in'
    },
    {
      src: womanTerror,
      title: `woman's terror`,
      type: 'ink on paper',
      dimensions: '11 in x 8.5 in'
    },
    {
      src: formSheres,
      title: 'form spheres',
      type: 'ink on paper',
      dimensions: '11 in x 12 in'
    },
    {
      src: naked,
      title: 'naked trees',
      type: 'ink on paper',
      dimensions: '5 in x 8 in'
    },
    {
      src: road,
      title: 'road trip',
      type: 'ink on paper',
      dimensions: '12 in x 9 in'
    }
  ];
  

  export const illustration = [
    {
      src: treeLives,
      title: 'tree with three lives',
      type: 'book',
      info: 'prose poem Survivor Tree 9/11 by Jo Perry'
    },
    {
      src: my,
      title: 'my young roots bundled in a ball, I was transported',
      type: 'book',
      info: 'prose poem by Jo Perry'
    },
     {
      src: iWas,
      title: 'i was ordinary',
      type: 'book',
      info: 'prose poem by Jo Perry'
    },
    {
      src: did,
      title: 'did the sun explode',
      type: 'book',
      info: 'prose poem by Jo Perry'
    },
    {
      src: one,
      title: 'one tower fell',
      type: 'book',
      info: 'prose poem by Jo Perry'
    },
    {
      src: treeWindow,
      title: 'the tree in the window',
      type: 'book',
      info: `prose poem Anne Frank's tree by Jo Perry`
    },
    {
      src: inSummer,
      title: 'in summer cloud chased cloud',
      type: 'book',
      info: 'prose poem by Jo Perry'
    },
    {
      src: upon,
      title: 'upon children wearing colored stars',
      type: 'book',
      info: 'prose poem by Jo Perry'
    },
    {
      src: winter,
      title: 'the winter moon perched',
      type: 'book',
      info: 'prose poem by Jo Perry'
    },
    {
      src: reborn,
      title: 'reborn in red-budded saplings the tree grows again',
      type: 'book',
      info: 'prose poem by Jo Perry'
    },
    {
      src: long,
      title: 'along the boggy path',
      type: 'book',
      info: 'The Night of the Slippery Snipe by Jo Perry'
    },
    {
      src: snipeNite,
      title: 'skittish snipes',
      type: 'book',
      info: 'poem The Night of the Slippery Snipe by Jo Perry'
    },
    {
      src: josephine,
      title: 'josephine',
      type: 'icon',
      info: 'josephine LA emblem'
    },
];

export const allArt = [
  ...paint,
  ...sketch,
  ...illustration
];

