import { observable, action, computed } from "mobx";
import {paint, sketch, illustration} from './ArtworkList';
import { Observable } from "rxjs";

export const Screens = {
  PAINT: 'PAINT',
  SKETCH: 'SKETCH',
  ILLUSTRATION: 'ILLUSTRATION',
  CONTACT: 'CONTACT'
}

const ListLengths = {
  PAINT: paint.length,
  SKETCH: sketch.length,
  ILLUSTRATION: illustration.length
}

class Store {
  @observable currentScreen = Screens.PAINT;
  @observable currentIndexes = new Map();

  constructor() {
    this.currentIndexes.set(Screens.PAINT, 0);
    this.currentIndexes.set(Screens.SKETCH, 0);
    this.currentIndexes.set(Screens.ILLUSTRATION, 0);
  }

  @action goTo = (screen) => {
    this.currentScreen = screen;
  }

  @action next = () => {
    const mod = ListLengths[this.currentScreen];
    const nextIndex = (this.currentIndex + 1) % mod;
    this.currentIndexes.set(this.currentScreen, nextIndex);
  }

  @action prev = () => {
    const prevIndex = this.currentIndex === 0 ? ListLengths[this.currentScreen] - 1 : this.currentIndex - 1;
    this.currentIndexes.set(this.currentScreen, prevIndex);
    console.log(prevIndex)
  }

  @computed get currentIndex() {
    return this.currentIndexes.get(this.currentScreen);
  }

  @computed get currentArt() {
    switch (this.currentScreen) {
      case Screens.PAINT: return paint[this.currentIndex];
      case Screens.SKETCH: return sketch[this.currentIndex];
      case Screens.ILLUSTRATION: return illustration[this.currentIndex];
      default: return paint[0];
    }
  }

  @computed get showContact() {
    return this.currentScreen === Screens.CONTACT;
  }
}

export default Store;