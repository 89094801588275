import React, { Component } from 'react';
import './App.css';
import { observer } from 'mobx-react';
import { Screens } from './Store';
import Artwork from './Artwork';
import Artist from './Artist';

@observer
class App extends Component {
  render() {
    const { store } = this.props;
    return (
      <div className="App">
        <div className="app-window">
        <header>
          <p>Wendy Vanguard</p>
        </header>
        <main>
          <NavMenu goTo={store.goTo} currentScreen={store.currentScreen} />
          {
            store.showContact 
            ?
            <Artist />
            : 
            <Artwork store={store} />
          }
        </main>
        <footer />
        </div>
      </div>
    );
  }
}

const NavMenu = ({ goTo, currentScreen }) => (
  <nav>
    <p className={`nav-item ${Screens.PAINT === currentScreen ? 'selected-screen': ''}`} onClick={() => goTo(Screens.PAINT)}>paint</p>
    <p className={`nav-item ${Screens.SKETCH === currentScreen ? 'selected-screen': ''}`} onClick={() => goTo(Screens.SKETCH)}>sketch</p>
    <p className={`nav-item ${Screens.ILLUSTRATION === currentScreen ? 'selected-screen': ''}`} onClick={() => goTo(Screens.ILLUSTRATION)}>illustration</p>
    <p className={`nav-item ${Screens.CONTACT === currentScreen ? 'selected-screen': ''}`} onClick={() => goTo(Screens.CONTACT)}>artist</p>
  </nav>
)

export default App;
