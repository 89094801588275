import wv from './assets/wv in studio.png';;
import { allArt } from './ArtworkList';

export const preloadImages = () => {
  allArt.forEach(art => {
    const image = new Image();
    image.src = art.src;
  });
  const image = new Image();
  image.src = wv;
};