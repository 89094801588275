import React, { Component } from 'react';
import { observer } from 'mobx-react';
import Swipe from 'react-easy-swipe';

@observer
class Artwork extends Component {
  render() {
    const { currentArt, next, prev } = this.props.store;
    return (
      <div className="artwork">
        <Swipe onSwipeLeft={next} onSwipeRight={prev} allowMouseEvents={true}>
        <div className="img-container" key={currentArt.title}>
          <img src={currentArt.src} />
          <div className="img-topper" />
        </div>
          <Arrows next={next} prev={prev} />
        </Swipe>
        <Description {...currentArt} />
        </div>
    )
  }
}

const Arrows = ({ next, prev }) => (
  <div className="arrows-container">
    <p className="arrow" onClick={prev}>{`<`}</p>
    <p className="arrow" onClick={next}>{`>`}</p>
  </div>
)

const Description = ({ title, type, dimensions, info }) => (
  <div className="description">
    <p>{title}</p>
    <p>{type}</p>
    {dimensions && <p>{dimensions}</p>}
    {info && <p>{info}</p>}
  </div>
)

export default Artwork;