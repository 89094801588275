import React, { Component } from 'react';
import wv from './assets/wv in studio.png';

class Artist extends Component {
  render() {
    return (
      <div className="artwork">
        <p className="artist-text">My focus is on self-expression and living and working from a place of resilience. I enjoy mentoring young people to express themselves and discover new creative territories.</p>
        <br />
        <p className="artist-text">You can follow me on <a href="https://instagram.com/wendyvanguarddobrowner" target="__blank">Instagram</a> and learn more about me from this episode of <a href="https://languageofcreativity.podbean.com/e/the-colors-of-life-wendy-vanguard-conceptual-artist/" target="__blank">The Language of Creativity Podcast</a>, on which I had the honor of being the guest.</p>

        <div className="img-container">
          <img src={wv} />
        </div>
        
        <p className="artist-text">I live in Los Angeles and Lone Pine with my family. Email me at wendyvanguard@me.com<br/><br/><a href="https://instagram.com/wendyvanguarddobrowner" target="__blank">@wendyvanguarddobrowner</a></p>
      </div>
    )
  }
}

export default Artist;